<template>
	<div class="title-bar-total">
		<img v-if="type == 1" class="icon" :src="require('@/assets/kh/ico_gty@2x.png')" />
		<img v-else-if="type == 2" class="icon" :src="require('@/assets/kh/ico_ddct@2x.png')" />
		<img v-else-if="type == 3" class="icon" :src="require('@/assets/kh/ico_jdmp@2x.png')" />
		<img v-else-if="type == 4" class="icon" :src="require('@/assets/kh/ico_yx@2x.png')" />
		<img v-else-if="type == 5" class="icon" :src="require('@/assets/kh/ico_hspx@2x.png')" />
		<img v-else-if="type == 6" class="icon" :src="require('@/assets/kh/ico_yly@2x.png')" />
		<img v-else-if="type == 8" class="icon" :src="require('@/assets/kh/ico_jdmp@2x.png')" />
		<img v-else-if="type == 9" class="icon" :src="require('@/assets/kh/ico_jjkh@2x.png')" />
		<img v-else-if="type == 10" class="icon"
			src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/ico_qz_lv%402x.png" />
		<img v-else-if="type == 11" class="icon"
			src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/ico_qz_lan%402x.png" />
		<img v-else class="icon" :src="require('@/assets/kh/ico_hczl@2x.png')" />

		<div v-if="type == 1" class="title_1">{{ title }}</div>
		<div v-else-if="type == 2" class="title_2">{{ title }}</div>
		<div v-else-if="type == 3" class="title_3">{{ title }}</div>
		<div v-else-if="type == 4" class="title_4">{{ title }}</div>
		<div v-else-if="type == 5" class="title_5">{{ title }}</div>
		<div v-else-if="type == 6" class="title_6">{{ title }}</div>
		<div v-else-if="type == 8" class="title_3">{{ title }}</div>
		<div v-else-if="type == 9" class="title_9">{{ title }}</div>
		<div v-else-if="type == 10" class="title_3">{{ title }}</div>
		<div v-else-if="type == 11" class="title_9">{{ title }}</div>
		<div v-else class="title_7">{{ title }}</div>

		<div class="desc">{{ desc }}</div>

		<div v-if="tab_array.length != 0">
			<div v-if="type == 1" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_gt@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai">{{ item }}</div>
				</div>
			</div>
			<div v-else-if="type == 2" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_ct@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai2">{{ item }}</div>
				</div>
			</div>
			<div v-else-if="type == 3" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_mp@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai3">{{ item }}</div>
				</div>
			</div>
			<div v-else-if="type == 4" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_yx@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai4">{{ item }}</div>
				</div>
			</div>
			<div v-else-if="type == 5" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_hc@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai5">{{ item }}</div>
				</div>
			</div>
			<div v-else-if="type == 6" class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_yly@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai6">{{ item }}</div>
				</div>
			</div>
			<div v-else class="tab-array">
				<div class="tab" v-for="(item, index) in tab_array" :key="index" @click="clickTab(index)">
					<img class="tab-img" v-if="tab_index == index" src="../assets/kh/tab_mp@2x.png" />
					<img class="tab-img-bai" v-else src="../assets/kh/tab_bai@2x.png" />
					<div v-if="tab_index == index" class="tab-title">{{ item }}</div>
					<div v-else class="tab-title-bai3">{{ item }}</div>
				</div>
			</div>
		</div>

		<!-- <div v-if="hideMore == false" class="more" @click="clickMore">更多+</div> -->
	</div>
</template>

<script>
	export default {
		props: {
			type: Number, //1跟团游 2当地参团 3景点门票 4 研学 5红色 6游轮 7火车
			tab_array: Array,
			title: String,
			desc: String,
			hideMore: Boolean,
			tab_index: {
				type: Number,
				default: -1,
			},
		},
		methods: {
			clickMore() {
				let org = this.$route.query.org ? this.$route.query.org : ''
				switch (this.type) {
					case 1:
						window.open("/ProductList?type=1&tab_name=2-1&org=" + org, "_blank");
						break;
					case 2:
						window.open("/ProductList?type=2&tab_name=3-1&org=" + org, "_blank");
						break;
					case 3:
						window.open("/ProductList?type=3&tab_name=4&org=" + org, "_blank");
						break;
					case 4:
						window.open("/ProductList?type=4&tab_name=5&org=" + org, "_blank");
						break;
					case 5:
						window.open("/ProductList?type=7&tab_name=8&org=" + org, "_blank");
						break;
					case 6:
						window.open("/ProductList?type=9&tab_name=10-1&org=" + org, "_blank");
						break;
					case 7:
						window.open("/ProductList?type=10&tab_name=11&org=" + org, "_blank");
						break;
					case 9:
						window.open("/NewsList&org=" + org, "_blank");
						break;
					case 10:
						window.open("/VisaMoreCountry" + "?tab_name=7&org=" + org, "_blank");
						break;
					case 11:
						window.open("/VisaMoreProduct" + "?tab_name=7&org=" + org, "_blank");
						break;
				}
			},
			clickTab(index) {
				this.$emit("update:tab_index", index);
			},
		},
		data() {
			return {};
		},
	};
</script>

<style lang="less" scoped>
	.title-bar-total {
		display: flex;
		width: 100%;
		height: 72px;
		align-items: center;
		position: relative;

		.tab-array {
			display: flex;

			.tab {
				margin-left: 20px;
				width: 106px * 1.1;
				height: 38px * 1.1;
				margin-top: 5px;
				position: relative;
				cursor: pointer;

				.tab-img {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 38px;
				}

				.tab-img-bai {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 32px;
				}

				.tab-title {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #fff;
					opacity: 1;
				}

				.tab-title-bai {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #5e7cf1;
					opacity: 1;
				}

				.tab-title-bai2 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #ec946a;
					opacity: 1;
				}

				.tab-title-bai3 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #52ac6e;
					opacity: 1;
				}

				.tab-title-bai4 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #7c51e6;
					opacity: 1;
				}

				.tab-title-bai5 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #eb7261;
					opacity: 1;
				}

				.tab-title-bai6 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #507ee7;
					opacity: 1;
				}

				.tab-title-bai7 {
					position: absolute;
					top: 5px;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #eb7261;
					opacity: 1;
				}
			}
		}

		.icon {
			width: 32px;
			height: 32px;
			margin-left: 10px;
			margin-right: 10px;
		}

		.title_1 {
			font-size: 28px;
			line-height: 100%;
			color: #557cf9;
		}

		.title_2 {
			font-size: 28px;
			line-height: 100%;
			color: #fd8e60;
		}

		.title_3 {
			font-size: 28px;
			line-height: 100%;
			color: #00af67;
		}

		.title_4 {
			font-size: 28px;
			line-height: 100%;
			color: #854cef;
		}

		.title_5 {
			font-size: 28px;
			line-height: 100%;
			color: #fed6a2;
		}

		.title_6 {
			font-size: 28px;
			line-height: 100%;
			color: #3e7fef;
		}

		.title_7 {
			font-size: 28px;
			line-height: 100%;
			color: #ff695a;
		}

		.title_9 {
			font-size: 28px;
			line-height: 100%;
			color: #3e97ef;
		}

		.desc {
			margin-left: 45px;
			font-size: 18px;
			color: #c2c2c2;
			opacity: 1;
		}

		.more {
			position: absolute;
			line-height: 100%;
			right: 0px;
			font-size: 14px;
			color: #666666;
		}

		.more:hover {
			cursor: pointer;
			color: var(--hover_color);
		}
	}
</style>